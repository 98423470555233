<template>
	<span>
		<ValidationObserver ref="VForm" v-slot="{handleSubmit}">
			<b-form @submit.prevent="handleSubmit(doSubmitCRUD)">
				<b-tabs>
					<b-tab title="Contact Content">
						<b-card no-body>
							<b-card-body>
								<b-row>
									{{row}}
									<b-col lg="8">
										<b-row>
											<b-col lg="12">
												<b-form-group label-for="articleContent">
													<template #label>Content <span
															class="text-danger mr5">*</span></template>
													<ckeditor v-model="row.ap_content" />
												</b-form-group>
											</b-col>

											<b-col lg="8">
												<b-form-group label-for="articleTitle">
													<template #label>Slug<span
															class="text-danger mr5">*</span></template>
													<b-form-input id="articleTitle" v-model="row.ap_slug"
														placeholder="e.g https://" />
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
									<b-col lg="4">
										<div class="side_wrap">
											<b-row>
												<b-col lg="12" class="mb-2">
													<div class="file-uploader">
														<label class="control-label">
															Image <span class="text-danger">*</span>
														</label>
														<Uploader v-model="row.ap_image" type="user" label="Image"/>
													</div>
												</b-col>

												<b-col lg="12" class="mb-2">
													<b-form-group>
														<label>Alt Image<span
																class="text-danger mr5">*</span></label>
														<b-form-input id="input-formatter" v-model="row.ap_alt_image"
															placeholder="e.g Image title">
														</b-form-input>
													</b-form-group>
												</b-col>

												<b-col lg="12" class="mb-2">
													<b-form-group label-for="articleAuthor">
														<template #label>Author<span
																class="text-danger mr5">*</span></template>
														<b-form-input id="articleAuthor" v-model="row.ap_author"
															placeholder="e.g Administrator" />
													</b-form-group>
												</b-col>

												<b-col lg="12" class="mb-2">
													<b-form-group label-for="articlePubDate">
														<template #label>Publish Date<span
																class="text-danger mr5">*</span></template>
														<v-date-picker v-model="row.ap_publish_date" :popover="{ visibility: 'click' }">
															<template v-slot="{ inputValue, inputEvents }">
																<input class="bg-white custom_inp px-2 py-1 rounded"
																	:value="inputValue" v-on="inputEvents" />
															</template>
														</v-date-picker>
													</b-form-group>
												</b-col>
											</b-row>
										</div>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-tab>

					<b-tab title="SEO Settings">
						<b-card>
							<b-row>
								<b-col lg="12">
									<b-form-group label-for="homeMetaTitle">
										<template #label>
											Meta Title
											<b-badge variant="info" pill class="help-badge"
												v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
												<i class="fas fa-question"></i>
											</b-badge>
										</template>
										<b-form-input id="homeMetaTitle" placeholder="Meta Title"
											v-model="row.ap_meta_title" />
									</b-form-group>
								</b-col>
								<b-col lg="9">
									<b-form-group label-for="homeMetaDesc">
										<template #label>
											Meta Description
											<b-badge variant="info" pill class="help-badge"
												v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
												<i class="fas fa-question"></i>
											</b-badge>
										</template>
										<b-form-textarea rows="4" id="homeMetaDesc" placeholder="Meta Description"
											v-model="row.ap_meta_description" />
									</b-form-group>
								</b-col>
								<b-col lg="9">
									<b-form-group label-for="homeMetaTags">
										<template #label>
											Meta Tags
											<b-badge variant="info" pill class="help-badge"
												v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
												<i class="fas fa-question"></i>
											</b-badge>
										</template>
										<b-form-tags id="homeMetaTags" placeholder="Type and press enter ..."
											remove-on-delete tag-variant="success" tag-class="text-white"
											v-model="row.ap_meta_keywords" />
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>
					</b-tab>

					<b-tab title="Ads Placement">
						<b-card no-body>
							<b-card-body>
								<div class="row">
									<div class="col-md-12" id="ads_placement">
										<div class="mb_20">
											<b-form-group label-for="articleTitle">
												<template #label>Ads Device Type</template>
												<b-form-radio-group v-model="selected" :options="ads_device"
													class="mb-3" value-field="item" text-field="name"
													disabled-field="notEnabled">
												</b-form-radio-group>
											</b-form-group>
										</div>
										<b-tabs pills card vertical nav-wrapper-class="w-20" v-if="selected == 'A'"
											style="padding:0;">
											<b-tab title="Top Ads" active>
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
											<b-tab title="Ads Sidebar #1">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
											<b-tab title="Ads Sidebar #2">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
										</b-tabs>

										<b-tabs v-else pills card vertical nav-wrapper-class="w-20">
											<b-tab title="Below Header">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
											<b-tab title="Below Title">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
											<b-tab title="Below Image Thumbnail">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
											<b-tab title="Every 2 Paragraphs">
												<b-row>
													<b-col cols="12">
														<div class="table-responsive">
															<table
																class="table table-hover table-striped table-bordered">
																<thead>
																	<tr>
																		<th scope="col"></th>
																		<th scope="col">Ads Name</th>
																		<th scope="col">Ads Size</th>
																		<th scope="col">Last Modified</th>
																		<th scope="col">Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<b-form-group>
																				<b-form-checkbox switch
																					id="activeAd" size="lg">
																				</b-form-checkbox>
																			</b-form-group>
																		</td>
																		<td>
																			Ads #1
																		</td>
																		<td>728x90</td>
																		<td>
																			2021-11-03 13:52:00
																		</td>
																		<td>
																			<a href="#" class="icon_action"><i
																					class="ti-marker-alt"></i></a>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="7">
														<b-form-group label-for="articleTitle">
															<template #label>Ads Name</template>
															<b-form-input id="articleTitle"
																v-model="row.title" placeholder="#ads 1" />
														</b-form-group>
														<b-form-group label-for="articleTitle">
															<template #label>Ads Size<span
																	class="text-danger mr5">*</span></template>
															<v-select placeholer="Select Category"
																v-model="selected_ads_size" :options="ads_size" />
														</b-form-group>
													</b-col>
													<b-col md="9">
														<b-form-group label-for="articleTitle">
															<template #label>Insert code<span
																	class="text-danger mr5">*</span>
																<b-badge variant="info" pill class="help-badge"
																	v-b-tooltip.hover.right="'Insert your adsense code'">
																	<i class="fas fa-question"></i>
																</b-badge>
															</template>
															<b-form-textarea id="textarea" v-model="text"
																placeholder="Enter something..." rows="5"
																max-rows="5">
															</b-form-textarea>
														</b-form-group>
													</b-col>
													<b-col cols="12">
														<b-form-group>
															<b-form-checkbox switch id="activeAd" size="lg">Activate
																Ad?</b-form-checkbox>
														</b-form-group>
													</b-col>
												</b-row>
											</b-tab>
										</b-tabs>
									</div>
								</div>
							</b-card-body>
						</b-card>
					</b-tab>
				</b-tabs>
				<b-card no-body>
					<template #footer>
						<div class="text-right">
							<b-button class="btn-rounded" variant="default" @click="cancelAddUpdate">Cancel
							</b-button>
							<b-button class="btn-rounded" variant="default" @click="cancelAddUpdate">Save Draft
							</b-button>
							<b-button class="btn-rounded" variant="success" type="submit">Publish</b-button>
						</div>
					</template>
				</b-card>
			</b-form>
		</ValidationObserver>
	</span>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'

	export default {
		name: 'Article',
		extends: GlobalVue,

		mounted() {
			this.apiGet()
		},

		methods: {
			// loadSeoData() {
			// 	let datas = this.dataSrc.seo
			// 	this.seoRow = datas
			// },
			// loadArticleData() {
			// 	let datas = this.dataSrc.articleList
			// 	datas.forEach(el => {
			// 		this.articleData.push(el)
			// 	})
			// }
		},
		data() {
			return {
				selected: 'A',
				ads_device: [{
						item: 'A',
						name: 'Desktop'
					},
					{
						item: 'B',
						name: 'Mobile'
					}
				],
				date: new Date(),
				days: [],
				featured: 'not_accepted',
				seoRow: {},
				articleRow: {},
				articleData: [],
				text: '',
				rows: 30,
				currentPage: 5,
				selected_category: 'All Category',
				category: [
					'All Category',
					'Pinjaman Online',
					'Asuransi',
					'Bank Digital',
					'Investasi',
					'Rencana Keuangan',
				],
				selected_sort: 'Sort By',
				sort: [
					'Sort By',
					'Most Viewed',
					'Least Viewed',
					'Oldest',
					'Newest',
				],
				selected_ads_size: 'Select Ads Size',
				ads_size: [
					'Select Ads Size',
					'300x250',
					'336x280',
					'728x90',
					'300x600',
					'320x100',
					'200x50',
					'320x20',
				],
				selected_parent: ' ---Select Parent Catgeory ---',
				parent: [
					' ---Select Parent Catgeory ---',
					'Asuransi',
					'Advetorial',
					'Pengeluaran',
					'Saham'
				],
				selected_comment: 'Y',
				comment: [{
						item: 'Y',
						name: 'Yes'
					},
					{
						item: 'N',
						name: 'No'
					}
				],
			}
		},
		watch: {
			$route() {
				this.apiGet()
				if (!this.isList) {
					if (!this.isAdd) {
						this.articleRow = this.articleData.find(el => el.id == this.$route.params.slug)
						console.log(this.articleRow)
					} else {
						this.articleRow = {}
					}
				}
			}
		}
	}
</script>