<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="2">
              <h5 class="card-title">Messages</h5>
            </b-col>
            <b-col lg="3">
              <v-date-picker v-model="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex items-center">
                    <input class="bg-white custom_inp px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                  </div>
                </template>
              </v-date-picker>
            </b-col>
            <b-col lg="3">
              <v-date-picker v-model="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex items-center">
                    <input class="bg-white custom_inp px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
                  </div>
                </template>
              </v-date-picker>
            </b-col>
            <b-col lg="4">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name</th>
                <th>Email Address</th>
                <th>No Handphone</th>
                <th>Subject</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{user.no}}</td>
                <td>{{user.fullname}}</td>
                <td>{{user.email_address}}</td>
                <td>{{user.no_hp}}</td>
                <td>{{user.subject}}</td>
                <td><span class="badge badge-success">Unread</span></td>
                <td><a href="#" class="icon_action"><i class="ti-eye" id="show-btn" v-b-modal.modal-1></i></a></td>
                <b-modal id="modal-1" title="Message">
                  <b-row>
                    <b-col lg="12">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam cupiditate quo quas, ab natus
                        culpa consectetur cum a quaerat optio sunt explicabo accusantium sapiente dicta consequatur.
                        Sint numquam pariatur explicabo.
                      </p>
                    </b-col>

                  </b-row>
                  <template #modal-footer>
                    <div class="w-100">
                      <b-button variant="success" class="float-right">
                        Oke
                      </b-button>
                    </div>
                  </template>
                </b-modal>
              </tr>
            </tbody>
          </table>
        </b-card-body>
        <b-card-footer>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev" next-text="Next">
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '../libs/Global.vue'
  import BoPageTitle from '@/components/BoPageTitle.vue'
  export default {
    extends: GlobalVue,
    components: {
      BoPageTitle,
    },
    data() {
      return {
        rows: 30,
        perPage: 1,
        date: new Date(),
        currentPage: 5,
        value: '',
        value2: '',
        users: [{
            no: 1,
            fullname: 'Tita Aprilianti',
            email_address: 'tita@lingkar9.com',
            no_hp: '+6285890302529',
            subject: 'Hai saya ingin bertanya',
          },
          {
            no: 2,
            fullname: 'Tita Aprilianti',
            email_address: 'tita@lingkar9.com',
            no_hp: '+6285890302529',
            subject: 'hai saya ingin bertanya',
          },
          {
            no: 3,
            fullname: 'Tita Aprilianti',
            email_address: 'tita@lingkar9.com',
            no_hp: '+6285890302529',
            subject: 'hai saya ingin bertanya',
          }
        ]
      }
    }
  }
</script>