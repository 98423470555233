<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <b-tabs>
        <b-tab title="Service">
          <bo-card title="Slideshow">
            <b-row cols="1" cols-md="3" cols-lg="4" class="gutter-2">
              <b-col v-for="(slider, key) in sliderData" :key="key">
                <bo-card-img :title="slider.title" :src="slider.src">
                  <template #buttons>
                    <b-button variant="secondary" size="sm" pill @click="updateSlide(key)" :disabled="slideId == key">
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                    <b-button variant="danger" size="sm" pill>
                      <i class="far fa-trash-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
              <b-col>
                <a href="javascript:;" class="card-img-adder" @click="addSlide">
                  <i class="fas fa-plus"></i>
                  <span>Add Slider</span>
                </a>
              </b-col>
            </b-row>
            <b-collapse class="card-collapse" v-model="sliderCollapse">
              <b-card no-body class="border mb-0">
                <b-card-header>
                  <b-card-title title-tag="h5">{{ `${slideMode} Slide` }}</b-card-title>
                </b-card-header>
                <b-form @submit.prevent="submitSlider(slideId)">
                  <b-card-body>
                    <b-row>
                      <b-col md="7">
                        <b-form-group label="Title" label-for="slideshowTitle">
                          <b-form-input id="slideshowTitle" placeholder="Title shown on slide" v-model="slideRow.title" />
                        </b-form-group>
                        <b-form-group label="Description" label-for="slideshowDesc">
                          <b-form-textarea id="slideshowDesc" placeholder="Slide Content"
                            v-model="slideRow.description" />
                        </b-form-group>
                        <b-form-row>
                          <b-col md="3">
                            <b-form-group label="Title Color" label-for="slideTitleColor">
                              <b-form-input type="color" id="slideTitleColor" v-model="slideRow.titleColor" />
                              <!--<h4 class="color-preview__title"
                                                              :style="{color: slideRow.titleColor}">Preview</h4>-->
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group label="Description Color" label-for="slideDescColor">
                              <b-form-input type="color" id="slideDescColor" v-model="slideRow.descriptionColor" />
                                <!--<p class="color-preview__desc"
                                :style="{color: slideRow.descriptionColor}">Color preview
                                for
                                slide description.</p>-->
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-group>
                          <b-form-checkbox v-model="slideRow.showButton">Show Button?
                          </b-form-checkbox>
                        </b-form-group>
                        <template v-if="slideRow.showButton">
                          <b-form-group label="Button Label" label-for="slideBtnLabel">
                            <b-form-input placeholder="e.g View More" id="slideBtnLabel" v-model="slideRow.btnLabel" />
                          </b-form-group>
                          <b-form-group label="Button Link" label-for="slideBtnLink">
                            <b-form-input placeholder="e.g http://www.lingkar9/about" id="slideBtnLink"
                              v-model="slideRow.btnLink" />
                          </b-form-group>
                        </template>
                      </b-col>
                      <b-col md="5">
                        <bo-uploader :img-src="slideRow.src" />
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button @click="cancelAddUpdate(cancelSlider)" class="btn-rounded" variant="outline-secondary">
                        Cancel</b-button>
                      <b-button type="submit" class="btn-rounded ml-2" variant="success">Submit
                      </b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </b-card>
            </b-collapse>
          </bo-card>
          <b-card no-body>
            <b-card-header>
              <b-row>
                <b-col lg="5">
                  <h5 class="card-title">Add Service</h5>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="8">
                  <b-row>
                    <b-col lg="12">
                      <b-form-group>
                        <label>Service Name<span class="text-danger mr5">*</span></label>
                        <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Software Development"
                          :formatter="formatter">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group class="mb-3">
                        <label>Service Short Description<span class="text-danger mr5">*</span></label>
                        <b-form-textarea id="textarea" v-bind="Test" placeholder="Enter something..." rows="3"
                          max-rows="6">
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group class="mb-3">
                        <label>Service Description<span class="text-danger mr5">*</span></label>
                        <ckeditor value="Hello, World!"></ckeditor>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="4">
                  <b-row>
                    <b-col lg="12" class="mb-2">
                      <div class="file-uploader">
                        <label>Main Image <span class="text-danger mr5">*</span></label>
                        <b-alert show variant="info" class="text-center my-2">
                          Min. Image Dimension: 1500x900<br />
                          File Formats: .png, .jpg, .svg<br />
                          Max. File Size: 2MB
                        </b-alert>
                        <div class="file-uploader-cta">
                          <label for="fileUploader" class="btn btn-uploader btn-block">
                            <i class="icon-picture" /> Upload
                          </label>
                          <b-form-file plain id="fileUploader" />
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="12" class="mb-2">
                      <b-form-group>
                        <label>Alt Image<span class="text-danger mr5">*</span></label>
                        <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Image title"
                          :formatter="formatter">
                        </b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12" class="mb-2">
                      <b-form-group>
                        <label>Background Service Icon<span class="text-danger mr5">*</span></label>
                        <b-form-select v-model="selected" :options="options"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12" class="mb-2">
                      <b-form-group>
                        <label>Status <span class="text-danger mr5">*</span></label>
                        <b-form-radio-group v-model="selected" :options="status" class="mb-3" value-field="item"
                          text-field="name" disabled-field="notEnabled">
                        </b-form-radio-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                
                    <b-col lg="12">
                      <b-form-group class="mb-0">
                        <label>What We Do<span class="text-danger mr5">*</span></label>
                      </b-form-group>
                      <b-row>
                        <b-col lg="12">
                          <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Sub Service Name</th>
                                  <th>Icon</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="sb in subservice" :key="sb.id">
                                  <td>{{sb.no}}</td>
                                  <td>{{sb.sub_service_name}}</td>
                                  <td><img src="http://placehold.it/60x60" alt="Sub Service Title" /></td>
                                  <td>{{sb.status}}</td>
                                  <td>
                                    <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                                    <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                                    <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="text-center">
                                    <b-button class="btn btn-outline-success btn-rounded" id="show-btn"
                                      @click="$bvModal.show('bv-modal-example')"> <i
                                        class="fa fa-plus-circle mr-2"></i>Add What We Do</b-button>
                                  </td>
                                  <div>
                                    <b-modal id="bv-modal-example" hide-footer>
                                      <template #modal-title>Add What We Do</template>
                                      <div class="d-block">
                                        <div class="file-uploader">
                                          <label>Main Image <span class="text-danger mr5">*</span></label>
                                          <b-alert show variant="info" class="text-center my-2">
                                            Min. Image Dimension: 1500x900<br />
                                            File Formats: .png, .jpg, .svg<br />
                                            Max. File Size: 2MB
                                          </b-alert>
                                          <div class="file-uploader-cta">
                                            <label for="fileUploader" class="btn btn-uploader btn-block">
                                              <i class="icon-picture" /> Upload
                                            </label>
                                            <b-form-file plain id="fileUploader" />
                                          </div>
                                        </div>
                                        <b-form-group class="mt-2">
                                          <label>Sub Service Name<span class="text-danger mr5">*</span></label>
                                          <b-form-input id="input-formatter" v-model="text1"
                                            placeholder="e.g Software Development" :formatter="formatter">
                                          </b-form-input>
                                        </b-form-group>
                                        <b-form-group class="mt-2">
                                          <label>Status <span class="text-danger mr5">*</span></label>
                                          <b-form-radio-group v-model="selected" :options="status" class="mb-3"
                                            value-field="item" text-field="name" disabled-field="notEnabled">
                                          </b-form-radio-group>
                                        </b-form-group>
                                      </div>
                                      <div  class="text-right">
                                          <button type="submit" class="btn  btn-rounded  btn-default mr-3"  @click="$bvModal.hide('bv-modal-example')">Cancel</button>
                                          <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
                                      </div>
                                    </b-modal>
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
              <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
            </b-col>
          </b-row>
        </b-card-footer>
          </b-card>
        </b-tab>
        <b-tab title="SEO Settings">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Home SEO Settings</b-card-title>
            </b-card-header>
            <b-form @submit.prevent="submitMethod">
              <b-card-body>
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
                </b-form-group>
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea id="homeMetaDesc" placeholder="Meta Description" v-model="seoRow.description" />
                </b-form-group>
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Tags
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="primary" tag-class="text-white" v-model="seoRow.keywords" />
                </b-form-group>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Submit</b-button>
                </div>
              </b-card-footer>
            </b-form>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  import BoCard from '@/components/BoCard.vue'
  import BoCardImg from '@/components/BoCardImg.vue'
  
  import BoUploader from '../components/BoUploader.vue'

  export default {
    name: 'BoHome',
    extends: GlobalVue,
    components: {
      BoCard,
      BoCardImg,
      BoUploader,
    },


    mounted() {
      this.loadSliderData()
      this.loadSeoData()
    },
    data() {
      return {
        sliderCollapse: false,
        slideMode: 'Add',
        sliderData: [],
        slideRow: {},
        seoRow: {},
        slideId: undefined,

        selected_status: 'Active',
        status: [
          'Active',
          'Inactive'
        ],

        subservice: [{
            no: 1,
            sub_service_name: 'Web Based Development',
            status: 'Active'
          },
          {
            no: 2,
            sub_service_name: 'Company Website & Microsite',
            status: 'Active'
          },
          {
            no: 3,
            sub_service_name: 'Mobile Application',
            status: 'Active'
          },
          {
            no: 4,
            sub_service_name: 'Odoo Consultation & Implementation',
            status: 'Active'
          }
        ],
      }
    },
    computed: {
      dataSrc() {
        return this.dummyData.home
      },
    },
    methods: {
      loadSliderData() {
        let datas = this.dataSrc.slider
        datas.forEach(el => {
          this.sliderData.push(el)
        })
      },
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      },
      addSlide() {
        this.slideMode = 'Add'
        this.sliderCollapse = true
        this.slideRow = {}
        this.slideId = undefined
      },
      updateSlide(k) {
        this.slideMode = 'Update'
        this.sliderCollapse = true
        this.slideRow = this.sliderData[k]
        this.slideId = k
      },
      submitSlider(k) {
        if (k) {
          this.sliderData.splice(k, 1, this.slideRow)
        } else {
          this.sliderData.push(this.slideRow)
        }
        this.sliderCollapse = false
      },
      cancelSlider() {
        this.slideRow = {}
        this.slideId = undefined
        this.sliderCollapse = false
      }
    }
  }
</script>