<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <bo-card title="Services List" v-if="isList">
        <template #filters>
          <b-row align-h="end">
            <b-col lg="3">
              <v-select placeholder="Select Status" label="text" v-model="selected_status"
                :options="statusOptions" />
            </b-col>

            <b-col lg="5">
              <bo-search />
            </b-col>
          </b-row>
        </template>
        <b-row class="gutter-2">
          <b-col lg="4" v-for="(v, k) in serviceData" :key="k">
            <article class="card_list">
              <div class="bulle_cta">
                <b-button variant="secondary" class="btn-icon" pill :to="{name: $route.name, params: { slug: k+1 }}">
                  <i class="fas fa-pencil-alt" />
                </b-button>
                <b-button variant="danger" class="btn-icon" pill @click="deleteItem(k, serviceData)">
                  <i class="far fa-trash-alt" />
                </b-button>
              </div>
              <div class="wrap_post_content">
                <div class="ic_service">
                  <b-img :src="v.image" />
                </div>
                <h3>{{ v.name }}</h3>
                <p>{{ v.shortDesc }}</p>
                <b-badge class="mt-2" :variant="v.status == 'Y' ? 'success' : 'danger'">
                  {{ v.status == 'Y' ? 'Active' : 'Inactive' }}</b-badge>
              </div>
            </article>
          </b-col>
        </b-row>
      </bo-card>
      <b-tabs v-else>
        <b-tab title="Service">
          <b-form @submit.prevent="handleServiceSubmit($route.params.slug - 1)">
            <bo-card title="Hero Image">
              <b-row cols="1" cols-md="3" class="gutter-2">
                <b-col>
                  <bo-card-img :title="serviceRow.hero.title" :src="serviceRow.hero.src">
                    <template #buttons>
                      <b-button
                        variant="secondary"
                        size="sm"
                        pill
                        @click="heroCollapse = true"
                        :disabled="heroCollapse"
                        class="btn-icon"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
              <b-collapse class="card-collapse" v-model="heroCollapse">
                <b-card no-body class="border mb-0">
                  <b-card-header>
                    <b-card-title title-tag="h5">Update Hero Image</b-card-title>
                  </b-card-header>
                  <b-form @submit.prevent="submitSlider()">
                    <b-card-body>
                      <b-row>
                        <b-col md="7">
                          <b-form-group label="Title" label-for="slideshowTitle">
                            <b-form-input id="slideshowTitle" placeholder="Title shown on slide" v-model="serviceRow.hero.title" />
                          </b-form-group>
                          <b-form-group label="Description" label-for="slideshowDesc">
                            <b-form-textarea id="slideshowDesc" placeholder="Slide Content" v-model="serviceRow.hero.description" />
                          </b-form-group>
                        </b-col>
                        <b-col md="5">
                          <bo-uploader :img-src="serviceRow.hero.src" />
                        </b-col>
                      </b-row>
                    </b-card-body>
                    <b-card-footer>
                      <div class="text-right">
                        <b-button @click="cancelAddUpdate(closeHero)" class="btn-rounded" variant="outline-secondary">Cancel
                        </b-button>
                        <b-button type="submit" class="btn-rounded ml-2" variant="primary">Submit</b-button>
                      </div>
                    </b-card-footer>
                  </b-form>
                </b-card>
              </b-collapse>
            </bo-card>
            <b-card no-body>
              <b-card-header>
                <b-row>
                  <b-col lg="5">
                    <h5 class="card-title">Add Service</h5>
                  </b-col>
                </b-row>
              </b-card-header>
                <b-card-body>
                <b-row>
                  <b-col lg="8">
                    <b-row>
                      <b-col lg="12">
                        <b-form-group>
                          <label>Service Name<span class="text-danger mr5">*</span></label>
                          <b-form-input id="input-formatter" v-model="serviceRow.name"
                            placeholder="e.g Software Development" />
                        </b-form-group>
                      </b-col>

                      <b-col lg="12">
                        <b-form-group class="mb-3">
                          <label>Service Short Description<span class="text-danger mr5">*</span></label>
                          <b-form-textarea id="textarea" v-model="serviceRow.shortDesc" placeholder="Enter something..."
                            rows="3" max-rows="6">
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>

                      <b-col lg="12">
                        <b-form-group class="mb-3">
                          <label>Service Description<span class="text-danger mr5">*</span></label>
                          <ckeditor v-model="serviceRow.description"></ckeditor>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col lg="4">
                    <b-row>
                      <b-col lg="12" class="mb-2">
                        <bo-uploader :img-src="serviceRow.image" />
                      </b-col>
                      <b-col lg="12" class="mb-2">
                        <b-form-group>
                          <label>Alt Image<span class="text-danger mr5">*</span></label>
                          <b-form-input id="input-formatter" v-model="serviceRow.name" placeholder="e.g Image title">
                          </b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col lg="12" class="mb-2">
                        <b-form-group>
                          <label>Background Service Icon<span class="text-danger mr5">*</span></label>
                          <b-form-select></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col lg="12" class="mb-2">
                        <b-form-group>
                          <label>Status <span class="text-danger mr5">*</span></label>
                          <b-form-radio-group v-model="serviceRow.status" :options="statusOptions" class="mb-3">
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="12">
                    <b-form-group class="mb-0">
                      <label>What We Do<span class="text-danger mr5">*</span></label>
                    </b-form-group>
                    <b-table
                      :fields="subServicesFields"
                      :items="serviceRow.subServices"
                      bordered
                      primary-key="name"
                      :tbody-transition-props="tableTransitionProps"
                    >
                      <template #cell(num)="data">
                        {{ data.index += 1 }}
                      </template>
                      <template #cell(status)="data">
                        <b-badge v-if="data.value == 'Y'" variant="success">Active</b-badge>
                        <b-badge v-else variant="danger">Inactive</b-badge>
                      </template>
                      <template #cell(action)="data">
                        <b-button variant="outline-info" class="btn-icon" size="sm" v-b-tooltip.hover="'Edit'"
                          @click="editSubService(data.index)"><i class="ti-marker-alt"></i></b-button>
                        <b-button variant="outline-warning" class="btn-icon" size="sm" v-b-tooltip.hover="'Change Status'"
                          @click="changeStatus(data.index, serviceRow.subServices)"><i class="ti-settings"></i></b-button>
                        <b-button variant="outline-danger" class="btn-icon" size="sm" v-b-tooltip.hover="'Delete'"
                          @click="deleteItem(data.index, serviceRow.subServices)"><i class="ti-trash"></i></b-button>
                      </template>
                      <template #custom-foot>
                        <b-tr>
                          <b-td colspan="4" class="text-center">
                            <b-button
                              variant="outline-success"
                              class="btn-rounded"
                              id="show-btn"
                              @click="addSubService()"
                            >
                              <i class="fa fa-plus-circle mr-2"></i>Add What We Do
                            </b-button>
                          </b-td>
                        </b-tr>
                      </template>
                    </b-table>
                    <b-modal id="subserviceModal" hide-footer @close.prevent="cancelAddUpdate(cancelSubservice)">
                      <template #modal-title>{{ subServiceRow.id ? 'Update' : 'Add'}} What We Do</template>
                      <b-form @submit.prevent="handleSubserviceSubmit(subServiceRow.id)">
                        <div class="d-block">
                          <bo-uploader />
                          <b-form-group class="mt-2">
                            <label>Sub Service Name<span class="text-danger mr5">*</span></label>
                            <b-form-input id="input-formatter" v-model="subServiceRow.name"
                              placeholder="e.g Software Development" />
                          </b-form-group>
                          <b-form-group class="mt-2">
                            <label>Status <span class="text-danger mr5">*</span></label>
                            <b-form-radio-group v-model="subServiceRow.status" :options="statusOptions" class="mb-3" />
                          </b-form-group>
                        </div>
                        <div class="text-right">
                          <b-button class="mr-3" @click="cancelAddUpdate(cancelSubservice)" variant="transparent">Cancel</b-button>
                          <b-button variant="success" type="submit">Submit</b-button>
                        </div>
                      </b-form>
                    </b-modal>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded mr-3" variant="transparent" @click="cancelAddUpdate(cancelService)">Cancel</b-button>
                  <b-button type="submit" class="btn-rounded" variant="success">Submit</b-button>
                </div>
              </b-card-footer>
            </b-card>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'

import BoCardImg from '@/components/BoCardImg'
import BoUploader from '@/components/BoUploader'

export default {
  name: 'BoService',
  extends: GlobalVue,
  components: {
    BoCardImg, BoUploader,
  },
  mounted() {
    this.loadServiceData()
  },
  data() {
    return {
      serviceData: [],
      heroCollapse: false,
      selected_status: {
        text: 'Active',
        value: 'Y'
      },
      subServicesFields: [
        { key: 'num', label: '#' },
        { key: 'name', label: 'Sub Service Name' },
        'status', 'action',
      ],
      subServiceRow: {},
    }
  },
  computed: {
    dataSrc() {
      return this.dummyData.service
    },
    serviceRow() {
      return this.$route.params.slug != 'add' ? this.serviceData[this.$route.params.slug - 1] : { hero: {} }
    }
  },
  methods: {
    loadServiceRow() {
      if (this.$route.params.slug != 'add') {
        this.serviceRow = this.serviceData[this.$route.params.slug - 1]
      } else{
        this.serviceRow = { hero: {} }
      }
    },
    loadServiceData() {
      let datas = this.dataSrc.serviceList
      datas.forEach(el => {
        this.serviceData.push(el)
      })
    },
    closeHero() {
      this.heroCollapse = false
    },
    handleSubserviceSubmit(key) {
      if (key) {
        this.serviceRow.subServices.splice(key, 1, this.subServiceRow)
        this.confirmChanges('update', 'Sub Service')
      } else {
        this.serviceRow.subServices.push(this.subServiceRow)
        this.confirmChanges('add', 'Sub Service')
      }
      this.$nextTick(() => this.$bvModal.hide('subserviceModal'))
    },
    cancelService() {
      this.serviceRow = {}
      this.$router.push({ name: 'BoService' })
    },
    cancelSubservice() {
      this.subServiceRow = {}
      this.$nextTick(() => this.$bvModal.hide('subserviceModal'))
    },
    addSubService() {
      this.subServiceRow = {}
      this.subServiceRow.status = 'Y'
      this.$bvModal.show('subserviceModal')
    },
    editSubService(key) {
      this.subServiceRow = this.serviceRow.subServices[key]
      this.subServiceRow.id = key
      this.$bvModal.show('subserviceModal')
    },
    handleServiceSubmit(key) {
      if (key) {
        this.serviceData.splice(key, 1, this.serviceRow)
        this.confirmChanges('update', 'Service')
      } else {
        this.serviceData.push(this.serviceRow)
        this.confirmChanges('add', 'Service')
      }
      this.$nextTick(() => this.$router.push({ name: 'BoService' }))
    },
  },
}
</script>